<template>
    <div>
        <rxNavBar title="房屋地址"></rxNavBar>
        <div class="roomChooseTitle">
            <!--搜索栏-->
            <div class="searchDiv">
                <van-search  placeholder="请输入搜索关键词" v-model="roomHouseName" size="large" @search="getPerRoomHouseList()"/>
            </div>
        </div>
        <div class="content" v-if="noContent"> <common-empty ></common-empty></div>
        <div class="content" v-else>
            <!-- 无合同时 -->
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
<!--                <van-list-->
<!--                        v-model="loading"-->
<!--                        :finished="finished"-->
<!--                        :finished-text="noContent? '':'已经到底了'"-->
<!--                        @load="this.onLoad"-->
<!--                >-->
                <div class="content_centre" v-for="(item,index) in dataList" :key="index"  @click="typeChoose(item.id)">
                    <!-- 状态 -->
<!--                     <div  class="content_title">{{item.contractStatusInfo}}</div>-->
                    <!-- 合同编号 -->
                    <div class="content_contract"><span>房屋地址:</span><span>{{item.contractCode}}</span></div>
                    <!-- 内容 -->
                    <div class="content_span">{{item.roomDetailedAddress}}</div>
                </div>
<!--                </van-list>-->
            </van-pull-refresh>

        </div>
    </div>

</template>

<script>
    import {NavBar,search} from "vant";
    import {findRentByRoomId, getPerRoomHouseList} from "../../getData/getData";
    import {globaluserId, responseUtil} from "../../libs/rongxunUtil";
    import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
    import Vue from 'vue';
    import { List } from 'vant';
    Vue.use(List);
    import { PullRefresh } from 'vant';
    Vue.use(PullRefresh);
    import util from "../contract/util2";

    export default {
        name: "roomChoose",
        data(){
            return{
                defaultPerContractDays: 0,     //配置定金失效天数
                earnestMoneyInvalidTime: '',   //定金失效时间 ，传参给新增预定
                dataList:[],
                //房源输入框
                roomHouseName:'',
                pageCount:'',//总页数
                loading: false,
                finished: false,
                currentPage:'1',//当前页
                numberPage:'10',//每页条数
                type:'1',//租客合同类型
                isLoading: false,
                noContent:false,//显示空页面
                mobile:'',//电话号码
                dictionaryTitle:'',//维修类型
                id:'',
                userId:'',
                prId:'',//潜在id
                flag:'',

            }
        },
        components:{
            [NavBar.name]:NavBar,
            [List.name]:List,
            [PullRefresh.name]:PullRefresh,
            [search .name]:search,
            rxNavBar
        },
        created() {
        this.mobile=this.$route.query.mobile,
        this.userId=this.$route.userId;
        this.prId=this.$route.query.prId
        this.flag=this.$route.query.flag
        this.getPerRoomHouseList();
        },
        methods:{
            //页面跳转

            typeChoose(roomHouse_id){
                var that = this;
                let queryData = {};
                queryData.roomId = roomHouse_id;
                findRentByRoomId(queryData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        let rentMoney,earnestMoney;
                        rentMoney = response.data.data && parseInt(response.data.data.offerPrice) || '-';
                        earnestMoney = response.data.data && parseInt(response.data.data.offerPrice) || '-';
                        if(response.data.data.subType == '8' && response.data.data.vacantDate){
                            that.earnestMoneyInvalidTime = util.dateAddDay(response.data.data.vacantDate,that.defaultPerContractDays)
                        }
                        that.$router.push({
                            name: 'newReserve',
                            query: {
                                roomId:roomHouse_id,
                                mobile:that.mobile,
                                userId:that.userId,
                                prId:that.prId,
                                flag:that.flag,
                                earnestMoneyInvalidTime: util.dateFormat(that.earnestMoneyInvalidTime),   //定金失效时间
                                earnestMoney: earnestMoney,  //定金
                            },
                        })

                    })
                })



            },

            onRefresh() {//刷新
                setTimeout(() => {
                    this.default();
                    this.isLoading = false;
                    this.getPerRoomHouseList()
                },800);
            },
            default(){//默认值
                this.dataList=[]
            },
            // 房源选择初始化
            getPerRoomHouseList: function () {
                var that = this
                let initData = {
                    flagType:'',
                    roomHouseName:that.roomHouseName
                }
                getPerRoomHouseList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if (response.data.code=='0'){
                            that.dataList=response.data.data.data
                            if(response.data.data.defaultPerContractDays){
                                that.earnestMoneyInvalidTime = util.dateAddDay(new Date(),response.data.data.defaultPerContractDays)
                                that.defaultPerContractDays = response.data.data.defaultPerContractDays
                            }
                            if (that.dataList.length==0){
                                that.noContent=true;
                            }else{
                                that.noContent=false;
                            }
                        }
                    })
                })
            },


    }

    }
</script>

<style scoped>
    .roomChooseTitle {
        position: fixed;
        width: 100%;
        top: 1.33333rem;
        z-index: 9999;
    }
    .content{
        width: 100%;
        height: auto;
        margin-top: 2rem;
    }
    .content_centre{
        width: 92%;
        height: auto;
        margin: 0 auto;
        border-radius: 8px;
        background-color: #ffffff;
        padding: 10px 0px;
        margin-bottom: 15px;

    }
    .content_title{
        border-radius: 8px 1px;
        background-image: linear-gradient(to right,#FFAE82, #FB5F2E);
        width: 42px;
        height: 17px;
        border-bottom-left-radius: 0;
        text-align: center;
        font-size: 8px;
        line-height: 17px;
        color: rgba(255, 255, 255, 0.7);
        font-family: PingFangSC-Regular;

    }
    /* 合同编号 */
    .content_contract{
        color: #EB8671;
        margin-top: 5px;
        margin-left: 15px;
        font-size: 15px;
        font-family: PingFangSC-Semibold;
    }
    .content_centre  div:not(:nth-of-type(1)){
        margin-left: 15px;
    }
    /* 内容 */
    .content_span{
        margin-top: 5px;
        color: rgba(34, 34, 34, 1);
        font-size: 15px;
        font-family: PingFangSC-Semibold;
    }

</style>
